export default {
  serviceName: 'hajimari socks',
  copy: '毎日を元気にはじめる靴下',
  labels: [
    { label: 'プライバシーポリシー', value: 'https://hajimarisocks.com/policies/privacy-policy' },
    { label: '特定商取引法に関する表記', value: 'https://hajimarisocks.com/policies/legal-notice' },
    { label: '返金ポリシー', value: 'https://hajimarisocks.com/policies/refund-policy' },
    { label: '配送ポリシー', value: 'https://hajimarisocks.com/policies/shipping-policy' },
    { label: '利用規約', value: 'https://hajimarisocks.com/policies/terms-of-service' }
  ],
  nav: {
    left: [
      { label: 'Adults', target: 'https://hajimarisocks.com/collections/adult' },
      // { label: 'Kids', target: '/kids' },
      { label: 'Gift', target: 'https://hajimarisocks.com/collections/gift' },
    ],
    right: [
      { label: 'instagram', target: 'https://www.instagram.com/hajimarisocks/', isBlank: true },
      { label: 'email', target: '/', isBlank: false },
    ]
  },
  button: {
    label: 'More',
  },
  globalNav: [
    { label: '運営会社', target: 'https://futurize.jp/', isBlank: true },
    { label: 'お問い合わせ', target: '/', isBlank: false },
  ],
  copyright: '{year} &copy; Futurize, Inc. all rights reserved.',
}
