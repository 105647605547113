import React, { useState, useEffect } from 'react'
/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core'
import ReactGA from 'react-ga'
import globalStyle from '../styles/globalStyle'
import RouteProvider from '../provider/RouteProvider'
import PoliciesProvider from '../provider/PoliciesProvider'
import Analytics from '../plugins/Analytics'
import AppHeader from '../components/AppHeader'
import AppFooter from '../components/AppFooter'
import { rem } from '../styles/style'
import { EASE_IN_OUT_QUART } from '../styles/.style'

Analytics.initialize()

const mainWrapper = css`
  position: relative;
  width: 100%;
  height: auto;
`

const backToTopButton = css`
  position: fixed;
  left: calc(100% - ${rem(100)});
  bottom: ${rem(193)};
  width: ${rem(58)};
  height: ${rem(58)};
  border-radius: ${rem(4)};
  background: #F4F4F4;
  transition: ${EASE_IN_OUT_QUART};
  opacity: 0;

  @media (max-width: 768px) {
    left: calc(100% - ${rem(50)});
    bottom: ${rem(198)};
    width: ${rem(36)};
    height: ${rem(36)};
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 42%;

    @media (max-width: 768px) {
      width: 40%;
    }
  }
`

const isEnableButton = css`
  opacity: 1;
  transition: ${EASE_IN_OUT_QUART};
`

const scrollTop = (): number => {
  return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
}

const Layout: React.FC = ({ children }: any) => {
  const [isTop, setIsTop] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)

  const onScroll = (): void => {
    const position = scrollTop()
    if (position >= 100) {
      setIsTop(true)
    } else {
      setIsTop(false)
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll)
    return (): void => document.removeEventListener('scroll', onScroll)
  })

  useEffect(() => {
    setTimeout(() => {
      setLoading(true)
    }, 3000)
  }, [])

  const handleBackToTop = () => {
    let getScrolled = ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop

    window.scrollTo( 0, Math.floor( getScrolled / 2 ) );
    if ( getScrolled > 0 ) {
      window.setTimeout( handleBackToTop, 30 );
    }
  }

  return (
    <div css={mainWrapper}>
      <Global styles={globalStyle}></Global>
      <RouteProvider>
        <AppHeader />
        <main>{children}</main>
        <PoliciesProvider>
          <AppFooter />
        </PoliciesProvider>
        <button type="button" css={[backToTopButton, isTop ? isEnableButton : '']} onClick={handleBackToTop}><img src={require('../images/icons/icon_arrow_top.svg')} /></button>
      </RouteProvider>
    </div>
  )
}

export default Layout
