export const BASE_TEXT_COLOR = '#4A4A4A'
export const BASE_WHITE_COLOR = '#ffffff'
export const BRAND_COLOR = '#FAA228'

export const DEFAULT_FONT_SIZE = 16
export const DEFAULT_FONT_FAMILY = `'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif`
export const ROBOTO_FONT = `'Roboto', sans-serif`
export const MAX_WIDTH = 1000

export const EASE_IN_OUT_QUART = 'all 600ms cubic-bezier(0.77, 0, 0.175, 1)'
