import ReactGA from 'react-ga'

type AnalyticsArgumentsType = {
  action: string
  label?: string
  value?: number 
}

export default class Analytics {
  static initialize() {
    ReactGA.initialize(process.env.GOOGLE_ANALYTICS_TRACKING_ID as string)
  }

  private static trackEvent(args: { category: string, action: string, label?: string, value?: number }) {
    ReactGA.event(args)
  }

  static trackProductEvent(args: AnalyticsArgumentsType) {
    this.trackEvent({
      category: 'Product',
      ...args
    })
  }

  static trackCartEvent(args: AnalyticsArgumentsType) {
    this.trackEvent({
      category: 'Cart',
      ...args
    })
  }

  static trackNavigationEvent(args: AnalyticsArgumentsType) {
    this.trackEvent({
      category: 'Navigation',
      ...args
    })
  }
}