import { css } from '@emotion/core'
import { MixinInner, rem, lineHeight } from '../../styles/style'
import { BASE_TEXT_COLOR } from '../../styles/.style'

export const appFooter = css`
  margin-top: ${rem(40)};
  padding: ${rem(40)} 0 ${rem(20)};
  background: #F7F7F7;

  @media (max-width: 768px) {
    padding: ${rem(14)} 0 ${rem(20)};
  }

  > div {
    ${MixinInner};
  }
`

export const appFooterCopyLight = css`
  font-size: ${rem(12)};
  line-height: ${lineHeight(12, 14)};
  color: ${BASE_TEXT_COLOR};
  text-align: center;

  @media (max-width: 768px) {
    font-size: ${rem(10)};
    line-height: ${lineHeight(10, 11)};
  }
`

export const appFooterNav = css`
  padding-bottom: ${rem(40)};

  @media (max-width: 768px) {
    padding-bottom: ${rem(30)};
  }

  ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
`

export const appFooterNavItem = css`
  @media (max-width: 768px) {
    margin-top: ${rem(16)};
  }

  &:not(:last-child) {
    &::after {
      content: '/';
      display: inline-block;
      vertical-align: middle;
      font-size: ${rem(14)};
      font-weight: normal;
      line-height: ${lineHeight(14, 20)};
      color: ${BASE_TEXT_COLOR};

      @media (max-width: 768px) {
        font-size: ${rem(12)};
        line-height: ${lineHeight(12, 18)};
      }
    }
  }

  > a {
    padding: 0 ${rem(16)};
    display: inline-block;
    vertical-align: middle;
    font-size: ${rem(14)};
    font-weight: normal;
    line-height: ${lineHeight(14, 20)};
    color: ${BASE_TEXT_COLOR};

    @media (max-width: 768px) {
      padding: 0 ${rem(8)};
      font-size: ${rem(12)};
      line-height: ${lineHeight(12, 18)};
    }
  }
`
