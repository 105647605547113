import { css } from '@emotion/core'
import { MAX_WIDTH, DEFAULT_FONT_SIZE } from './.style'

export const rem = (pixel: number) => {
  // pixel数値を渡すとrem変換を行う
  return `${pixel / DEFAULT_FONT_SIZE * DEFAULT_FONT_SIZE / parseFloat('16px')}rem`
}

export const lineHeight = (fontSize: number, designLineHeight: number) => {
  return designLineHeight / fontSize
}

export const MixinInner = css`
  position: relative;
  margin: 0 auto;
  padding: 0 ${rem(64)};
  width: 100%;
  max-width: ${MAX_WIDTH + 64 * 2}px;

  @media (max-width: 768px) {
    padding: 0 ${rem(16)};
    max-width: inherit;
  }
`
