/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { BASE_TEXT_COLOR, BASE_WHITE_COLOR, MAX_WIDTH, EASE_IN_OUT_QUART } from '../../styles/.style'
import { rem, lineHeight } from '../../styles/style'

export const appHeader = css`
  position: sticky;
  top: 0;
  left: 0;
  padding: ${rem(50)} ${rem(32)} ${rem(48)};
  width: 100%;
  background: ${BASE_WHITE_COLOR};
  border-bottom: 1px solid #EAEAEA;
  z-index: 999;
  transition: ${EASE_IN_OUT_QUART};

  @media (max-width: 768px) {
    padding: ${rem(14.5)} ${rem(20)} ${rem(12)};
  }
`

export const isHeaderScrollable = css`
  padding: ${rem(26)} ${rem(32)} ${rem(24)};
  transition: ${EASE_IN_OUT_QUART};

  @media (max-width: 768px) {
    padding: ${rem(12)} ${rem(20)} ${rem(10)};
  }
`

export const isHeaderNavOpen = css`
  border-bottom: none;
`

export const appHeaderInner = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: ${MAX_WIDTH}px;

  @media (max-width: 768px) {
    max-width: inherit;
  }

  ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const appMobileHamburger = css`
  display: none;
  position: absolute;
  top: ${rem(26)};
  left: ${rem(20)};
  width: ${rem(25)};
  height: calc(${rem(2)} + ${rem(10)} * 2);

  @media (max-width: 768px) {
    display: block;
  }
`

export const appMobileHamburgerLine = css`
  display: block;
  position: relative;
  font-size: 0;
  width: 100%;
  height: ${rem(2)};
  border-radius: ${rem(30)};
  background: ${BASE_TEXT_COLOR};
  transform: translateY(${rem(5)});
  transition: ${EASE_IN_OUT_QUART};

  &::before,
  &::after {
    width: 100%;
    height: ${rem(2)};
    border-radius: ${rem(30)};
    background: ${BASE_TEXT_COLOR};
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: ${rem(10)};
    transform: bottom 600ms cubic-bezier(0.77, 0, 0.175, 1);
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: ${rem(10)};
    transform: top 600ms cubic-bezier(0.77, 0, 0.175, 1);
  }
`

export const isMobileBurgerOpen = css`
  background: transparent;

  &::before {
    bottom: 0 !important;
    transform: rotate(-45deg);
    transition: bottom 600ms cubic-bezier(0.77, 0, 0.175, 1), transform 300ms 600ms cubic-bezier(0.77, 0, 0.175, 1);
  }
  &::after {
    top: 0 !important;
    transform: rotate(45deg);
    transition: top 600ms cubic-bezier(0.77, 0, 0.175, 1), transform 300ms 600ms cubic-bezier(0.77, 0, 0.175, 1)
  }
`

export const appHeaderPcLeftNav = css`
  li > a {
    display: block;
    font-size: ${rem(18)};
    line-height: ${lineHeight(18, 28.8)};
    font-weight: normal;
    color: ${BASE_TEXT_COLOR}
  }

  li > a:hover { text-decoration: underline; }

  li:nth-of-type(2) > a {
    padding: 0 ${rem(26)};
  }

  @media (max-width: 768px) {
    display: none;
  }
`

export const appGlobalLogo = css`
  margin-left: ${rem(43)};
  width: ${rem(225)};
  transition: ${EASE_IN_OUT_QUART};

  @media (max-width: 768px) {
    margin-left: 0;
    width: ${rem(95)};
  }

  a,
  img {
    display: block;
    width: 100%;
  }
`

export const isScrollableGloalLogo = css`
  width: ${rem(148)};
  transition: ${EASE_IN_OUT_QUART};

  @media (max-width: 768px) {
    width: ${rem(62)};
  }
`

export const appHeaderPcRightNav = css`
  li:nth-of-type(2) > a {
    margin: 0 ${rem(16)};
  }

  @media (max-width: 768px) {
    display: none;
  }
`

export const appHeaderExternalLink = css`
  display: block;
  position: relative;
  width: ${rem(48)};
  height: ${rem(48)};
  border-radius: 50%;

  @media (max-width: 768px) {
    margin: 0 ${rem(2)};
    width: ${rem(36)};
    height: ${rem(36)};
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${rem(26)};

    @media (max-width: 768px) {
      width: ${rem(20)};
    }
  }
`

export const appHeaderInternalLink = css`
  display: block;
  position: relative;
  width: ${rem(48)};
  height: ${rem(48)};
  border-radius: 50%;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${rem(26)};
  }
`

export const appHeaderSpNav = css`
  display: none;
  position: absolute;
  top: -${rem(250)};
  left: 0;
  padding: ${rem(30)} ${rem(20)} ${rem(32)};
  text-align: center;
  width: 100%;
  background: ${BASE_WHITE_COLOR};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11);
  transition: ${EASE_IN_OUT_QUART};

  @media (max-width: 768px) {
    display: block;
  }
`

export const isOpenSpNav = css`
  top: ${rem(74.5)};
`

export const appHeaderSpNavList = css`
  display: block;
  list-style-type: none;
  width: 100%;

  li {
    display: block;
    width: 100%;
  }
  li:not(:nth-of-type(1)) {
    margin-top: ${rem(26)};
  }

  li > a {
    font-size: ${rem(16)};
    line-height: ${lineHeight(16, 25.6)};
    color: ${BASE_TEXT_COLOR};
  }
`

export const appHeaderSpIconLinks = css`
  margin-top: ${rem(32)};

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
  }

  li {
    margin-top: 0;
  }
`
