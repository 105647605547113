import React, { useContext, useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import { jsx } from '@emotion/core'
import Analytics from '../../plugins/Analytics'
import { RouteContext } from '../../provider/RouteProvider'
import myLabels from '../../documents/common'

import {
  appHeader,
  isHeaderScrollable,
  isHeaderNavOpen,
  appHeaderInner,
  appMobileHamburger,
  appMobileHamburgerLine,
  isMobileBurgerOpen,
  appHeaderPcLeftNav,
  appGlobalLogo,
  isScrollableGloalLogo,
  appHeaderPcRightNav,
  appHeaderExternalLink,
  appHeaderInternalLink,
  appHeaderSpNav,
  appHeaderSpNavList,
  isOpenSpNav,
  appHeaderSpIconLinks
} from './style'

const AppHeader = () => {
  const { state } = useContext(RouteContext)
  const [isTop, setIsTop] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [cartItems, setCartItems] = useState<number>(0)
  const navRef = useRef<HTMLDivElement>(null)

  const serialNumbers = Array.from(new Array(101)).map((v, i) => i + 1)

  const throttle = (callback: any, wait: number) => {
    let firstCallFlg = true
    let runFlg = false
    return async () => {
      runFlg = true
      if (firstCallFlg) firstCallFlg = false
      else return
      do {
        callback()
        runFlg = false
        await new Promise((resolve) => setTimeout(resolve, wait))
      } while (runFlg)
      firstCallFlg = true
    }
  }

  const onScroll = (): void => {
    setIsTop(serialNumbers.includes(window.pageYOffset) ||
    window.pageYOffset > 100)
  }

  useEffect(() => {
    const quantities: Array<number> = []
    state?.checkout.lineItems.map((item: any) => {
      quantities.push(item.quantity)
    })
    setCartItems(quantities.reduce(function(a, x){return a + x;}, 0))
    document.addEventListener('scroll', throttle(onScroll, 50))
    return (): void => document.removeEventListener('scroll', throttle(onScroll, 50))
  })

  const handleLinkClick = (item: { label: string, target: string, isBlank: boolean }) => {
    Analytics.trackNavigationEvent({
      action: 'Click Header Menu Link',
      label: item.label
    })
  }

  const handleNavClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <header css={[appHeader, isTop ? isHeaderScrollable : '', isOpen ? isHeaderNavOpen : null]}>
      <div css={appHeaderInner}>
        <div css={appMobileHamburger}>
          <span css={[appMobileHamburgerLine, isOpen ? isMobileBurgerOpen : '']} onClick={handleNavClick}></span>
        </div>
        <nav css={appHeaderPcLeftNav}>
          <ul>
            {myLabels.nav.left.map((item: { label: string, target: string }, idx: number) => (
              <li key={`navigation-${idx}`}>
                <a href={item.target} rel="noopener">{item.label}</a>
              </li>
            ))}
          </ul>
        </nav>
        <h1 css={[appGlobalLogo, isTop ? isScrollableGloalLogo : '']}>
          <Link to={`/`}>
            <img src={require('../../images/logo.svg')} alt={myLabels.serviceName} />
          </Link>
        </h1>
        <nav css={appHeaderPcRightNav}>
          <ul>
            {myLabels.nav.right.map((item: { label: string, target: string, isBlank: boolean }, id: number) => (
              <li key={`nav-icon-${id}`}>
                {item.isBlank ?
                  <a href={item.target} target="_blank" css={appHeaderExternalLink} onClick={() => handleLinkClick(item)} rel="noopener"><img src={require(`../../images/icons/icon_${item.label}.svg`)} alt={item.label} /></a>
                :
                  <Link to={item.target} css={appHeaderInternalLink} onClick={() => handleLinkClick(item)}>
                    <img src={require(`../../images/icons/icon_${item.label}.svg`)} alt={item.label} />
                  </Link>
                }
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <nav css={[appHeaderSpNav, isOpen ? isOpenSpNav : '']} ref={navRef}>
        <ul css={appHeaderSpNavList}>
          {myLabels.nav.left.map((item: { label: string, target: string }, idx: number) => (
            <li key={`navigation-${idx}`}>
              <a href={item.target} rel="noopener">{item.label}</a>
            </li>
          ))}
        </ul>
        <div css={appHeaderSpIconLinks}>
          <ul>
            {myLabels.nav.right.map((item: { label: string, target: string, isBlank: boolean }, id: number) => {
              if (item.label !== 'cart') {
                return (
                  <li key={`nav-icon-${id}`}>
                    <a href={item.target} target={item.isBlank ? '_blank' : ''} css={appHeaderExternalLink} onClick={() => handleLinkClick(item)} rel="noopener"><img src={require(`../../images/icons/icon_${item.label}.svg`)} alt={item.label} /></a>
                  </li>
                )
              }
            })}
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default AppHeader
