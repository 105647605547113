import React, { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { ApolloProvider } from '@apollo/react-hooks'
import Client, { GraphModel } from 'shopify-buy'
import myEnv from '../config/env'
const dayjs = require('dayjs')
import { Product, Posts } from './types/types'

import { apollo } from '../plugins/apollo'

const client = Client.buildClient({
  storefrontAccessToken: `${process.env.SHOPIFY_ACCESS_TOKEN}`,
  domain: `${process.env.SHOP_NAME}.myshopify.com`,
})

interface RouteState {
  client: any
  adding: boolean
  checkout: GraphModel | any
  shop: object
  all: {
    page: number
    perPage: number
    totalPage: number
    data: Array<Product>
    list: Array<Product>,
    lineup: Array<Product>
  }
  adults: {
    page: number
    perPage: number
    totalPage: number
    data: Array<Product>
    list: Array<Product>
  }
  kids: {
    page: number
    perPage: number
    totalPage: number
    data: Array<Product>
    list: Array<Product>
  }
  gift: {
    page: number
    perPage: number
    totalPage: number
    data: Array<Product>
    list: Array<Product>
  }
  posts: Array<Posts>
}

export const initialRouteState = {
  client,
  adding: false,
  checkout: { lineItems: [] },
  shop: {},
  all: {
    page: myEnv.PAGE,
    perPage: myEnv.PERPAGE,
    totalPage: 1,
    data: [],
    list: [],
    lineup: []
  },
  adults: {
    page: myEnv.PAGE,
    perPage: myEnv.PERPAGE,
    totalPage: 1,
    data: [],
    list: []
  },
  kids: {
    page: myEnv.PAGE,
    perPage: myEnv.PERPAGE,
    totalPage: 1,
    data: [],
    list: []
  },
  gift: {
    page: myEnv.PAGE,
    perPage: myEnv.PERPAGE,
    totalPage: 1,
    data: [],
    list: []
  },
  posts: []
}

interface RouteProps {
  state: RouteState
  addVariantToCart: any
  removeLineItem: any
  updateLineItem: any
  nextPage: any
}

export const RouteContext = React.createContext<Partial<RouteProps>>({})

const RouteProvider = ({ children }: any) => {
  const [state, setState] = useState<RouteState>(initialRouteState)

  const sortArr = (arr: any) => {
    return [...arr].sort((a, b) => dayjs(b.node.publishedAt) - dayjs(a.node.publishedAt))
  }
  useEffect(() => {
    let $adults: Array<Product> = []
    let $kids: Array<Product> = []
    let $gift: Array<Product> = []

    const ajaxGetProducts = async () => {
      let resAdult = await apollo.query({query: gql`
        query {
          fetchProducts(first: 100, query: "product_type:Adult") {
            edges {
              node {
                id
                title
                publishedAt
                totalInventory
                images {
                  edges {
                    node {
                      originalSrc
                    }
                  }
                }
              }
            }
          }
        }
      `})

      let resKids = await apollo.query({query: gql`
        query {
          fetchProducts(first: 100, query: "product_type:Kids") {
            edges {
              node {
                id
                title
                publishedAt
                totalInventory
                images {
                  edges {
                    node {
                      originalSrc
                    }
                  }
                }
              }
            }
          }
        }
      `})

      let resGift = await apollo.query({query: gql`
        query {
          fetchProducts(first: 100, query: "product_type:Gift") {
            edges {
              node {
                id
                title
                publishedAt
                totalInventory
                images {
                  edges {
                    node {
                      originalSrc
                    }
                  }
                }
              }
            }
          }
        }
      `})

      $adults = resAdult.data.fetchProducts.edges
      $kids = resKids.data.fetchProducts.edges
      $gift = resGift.data.fetchProducts.edges

      const $all = $adults.concat($kids, $gift)

      const adults = {
        page: 1,
        perPage: 12,
        totalPage: Math.ceil(resAdult.data.fetchProducts.edges.length / state.adults.perPage),
        data: resAdult.data.fetchProducts.edges,
        list: resAdult.data.fetchProducts.edges.filter(
          (item: any, i: number) =>
            i >= (state.adults.page - 1) * state.adults.perPage && i < state.adults.page * state.adults.perPage
        )
      }

      const kids = {
        page: 1,
        perPage: 12,
        totalPage: Math.ceil(resKids.data.fetchProducts.edges.length / state.kids.perPage),
        data: resKids.data.fetchProducts.edges,
        list: resKids.data.fetchProducts.edges.filter(
          (item: any, i: number) =>
            i >= (state.kids.page - 1) * state.kids.perPage && i < state.kids.page * state.kids.perPage
        )
      }

      const gift = {
        page: 1,
        perPage: 12,
        totalPage: Math.ceil(resGift.data.fetchProducts.edges.length / state.gift.perPage),
        data: resGift.data.fetchProducts.edges,
        list: resGift.data.fetchProducts.edges.filter(
          (item: any, i: number) =>
            i >= (state.gift.page - 1) * state.gift.perPage && i < state.gift.page * state.gift.perPage
        )
      }

      const all = {
        page: 1,
        perPage: 12,
        totalPage: Math.ceil($all.length / state.all.perPage),
        data: $all,
        list: $all.filter(
          (item: any, i: number) =>
            i >= (state.all.page - 1) * state.all.perPage && i < state.all.page * state.all.perPage
        ),
        lineup: sortArr($all).slice(0, 6)
      }

      setState((prevState: any) => {
        return { ...prevState, adults, kids, all, gift }
      })
    }

    ajaxGetProducts()

    apollo.query({query: gql`
      query {
        fetchArticles(first: 10) {
          edges {
            node {
              id
              title
              content
              publishedAt
            }
          }
        }
      }
    `}).then(res => {
      setState((prevState: any) => {
        return { ...prevState, posts: res.data.fetchArticles.edges }
      })
    })
  }, [])

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined'
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('shopify_checkout_id')
        : null

      const setCheckoutInState = (checkout: any) => {
        if (isBrowser) {
          localStorage.setItem('shopify_checkout_id', checkout.id)
        }

        setState((prevState: any) => {
          return { ...prevState, checkout }
        })
      }

      const createNewCheckout = () => state.client.checkout.create()
      const fetchCheckout = (id: string | number) => state.client.checkout.fetch(id)

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID)
          // Make sure this cart hasn’t already been purchased.
          if (!checkout.completedAt) {
            setCheckoutInState(checkout)
            return
          }
        } catch (e) {
          localStorage.setItem('shopify_checkout_id', '')
        }
      }

      const newCheckout = await createNewCheckout()
      setCheckoutInState(newCheckout)
    }

    initializeCheckout()
  }, [state.client.checkout])

  const $state = {
    state,
    addVariantToCart: (variantId: string | number, quantity: string) => {
      if (variantId === '' || !quantity) {
        console.error('Both a size and quantity are required.')
        return
      }
      setState((prevState: any) => {
        return { ...prevState, adding: true }
      })

      const { checkout, client } = state

      const checkoutId = checkout.id
      const lineItemsToUpdate = [
        { variantId, quantity: parseInt(quantity, 10) },
      ]
      return client.checkout
        .addLineItems(checkoutId, lineItemsToUpdate)
        .then((checkout: any) => {
          setState((prevState: any) => {
            return { ...prevState, checkout, adding: false }
          })
        })
    },
    removeLineItem: (client: any, checkoutID: string | any, lineItemID: string) => {
      return client.checkout
        .removeLineItems(checkoutID, [lineItemID])
        .then((res: any) => {
          setState((prevState: any) => {
            return { ...prevState, checkout: res }
          })
        })
    },
    updateLineItem: (client: any, checkoutID: string | any, lineItemID: string, quantity: string) => {
      const lineItemsToUpdate = [
        { id: lineItemID, quantity: parseInt(quantity, 10) },
      ]

      return client.checkout
        .updateLineItems(checkoutID, lineItemsToUpdate)
        .then((res: any) => {
          setState((prevState: any) => {
            return { ...prevState, checkout: res }
          })
        })
    },
    nextPage: (label: string) => {
      switch (label) {
        case 'adults':
          setState((prevState: any) => {
            const page = state.adults.page + 1
            const add = state.adults.data.filter(
              (item: any, i: number) =>
                i >= (page - 1) * state.adults.perPage && i < page * state.adults.perPage
            )
            const list = state.adults.list.concat(add)
            return { ...prevState, adults: { page, perPage: state.adults.perPage, totalPage: state.adults.totalPage, data: state.adults.data, list } }
          })
        // case 'kids':
        //   setState((prevState: any) => {
        //     const page = state.kids.page + 1
        //     const list = state.kids.list.concat(
        //       state.kids.data.filter(
        //         (item: any, i: number) =>
        //           i >= (page - 1) * state.kids.perPage && i < page * state.kids.perPage
        //       )
        //     )
        //     return { ...prevState, kids: { page, perPage: state.kids.perPage, totalPage: state.kids.totalPage, data: state.kids.data, list } }
        //   })
        // case 'gift':
        //   setState((prevState: any) => {
        //     const page = state.gift.page + 1
        //     const list = state.gift.list.concat(
        //       state.gift.data.filter(
        //         (item: any, i: number) =>
        //           i >= (page - 1) * state.gift.perPage && i < page * state.gift.perPage
        //       )
        //     )
        //     return { ...prevState, gift: { page, perPage: state.gift.perPage, totalPage: state.gift.totalPage, data: state.gift.data, list } }
        //   })
      }
    }
  }

  return (
    <ApolloProvider client={apollo}>
      <RouteContext.Provider value={$state}>
        {children}
      </RouteContext.Provider>
    </ApolloProvider>
  )
}

export default RouteProvider
