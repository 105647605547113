import React, { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { ApolloProvider } from '@apollo/react-hooks'

import { apollo } from '../plugins/apollo'

type Policies = {
  handle: string
}

interface PoliciesState {
  data: Array<Policies>
}

export const initialPoliciesState = {
  data: []
}

interface PoliciesProps {
  policies: PoliciesState
}

export const PoliciesContext = React.createContext<Partial<PoliciesProps>>({})

const PoliciesProvider = ({ children }: any) => {
  const [policies, setPolicies] = useState<PoliciesState>(initialPoliciesState)

  useEffect(() => {
    apollo.query({query: gql`
      query {
        fetchShopPolicies {
          handle
          title
        }
      }
    `}).then(res => {
      setPolicies((prevState: any) => {
        return { ...prevState, data: res.data.fetchShopPolicies }
      })
    })
  }, [])

  return (
    <ApolloProvider client={apollo}>
      <PoliciesContext.Provider value={{ policies }}>
        {children}
      </PoliciesContext.Provider>
    </ApolloProvider>
  )
}

export default PoliciesProvider
