/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

const ogp = require('../images/ogp.jpg')

const SEO: React.FC = ({ title, description, image, noindex }: any) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            lang
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            author
            siteUrl
          }
        }
      }
    `
  )

  const {
    lang,
    defaultTitle,
    titleTemplate,
    defaultDescription,
    author,
    siteUrl
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || `${siteUrl}${ogp}`,
    noindex: noindex || true,
    path: `${siteUrl}${pathname}`
  }

  const noIndex = seo.noindex ? `noindex` : null

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={seo.title}
      titleTemplate={titleTemplate}
      meta={[
        { name: `description`, content: seo.description, },
        { name: `image`, content: seo.image },
        { property: `og:title`, content: seo.title, },
        { property: `og:description`, content: seo.description, },
        { property: `og:type`, content: `website`, },
        { property: `og:url`, content: seo.path },
        { property: `og:image`, content: seo.image },
        { property: `og:image:width`, content: `1200` },
        { property: `og:image:height`, content: `630` },
        { name: `twitter:title`, content: seo.title },
        { name: `twitter:image`, content: seo.image },
        { name: `twitter:card`, content: `summary`, },
        { name: `twitter:creator`, content: author, },
        { name: `twitter:title`, content: title, },
        { name: `twitter:description`, content: seo.description, },
        { name: `robots`, content: noIndex }
      ]}>
        <script src="//statics.a8.net/a8sales/a8sales.js"></script>
        <script src="//statics.a8.net/a8sales/a8crossDomain.js"></script>
      </Helmet>
  )
}

SEO.defaultProps = {
  title: '',
  description: '',
  image: null,
  noindex: false
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  noindex: PropTypes.bool
}

export default SEO
