import React from 'react'
import { jsx } from '@emotion/core'
import { Link } from 'gatsby'
const dayjs = require('dayjs')

import myLabels from '../../documents/common'
import { appFooter, appFooterNav, appFooterNavItem, appFooterCopyLight } from './style'

const AppFooter = () => {
  return (
    <footer css={appFooter}>
      <div>
        <nav css={appFooterNav}>
          <ul>
            {myLabels.labels.map((item, key) => (
              <li css={appFooterNavItem} key={key}>
                <a href={item.value} rel="noopener">{item.label}</a>
              </li>
            ))}
            {myLabels.globalNav.map((item, id) => (
              <li css={appFooterNavItem} key={id}>
                {item.isBlank ? <a href={item.target} target="_blank">{item.label}</a> : <Link to={item.target}>{item.label}</Link>}
              </li>
            ))}
          </ul>
        </nav>
        <p css={appFooterCopyLight}>
          <small dangerouslySetInnerHTML={{ __html: myLabels.copyright.replace('{year}', dayjs().year()) }}></small>
        </p>
      </div>
    </footer>
  )
}

export default AppFooter
