import { css } from '@emotion/core'
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_FONT_FAMILY,
  BASE_TEXT_COLOR,
  BASE_WHITE_COLOR,
} from './.style'

const globalStyle = css`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

  html {
    display: block;
    font-size: ${DEFAULT_FONT_SIZE};
  }

  body {
    background: ${BASE_WHITE_COLOR};
    color: ${BASE_TEXT_COLOR};
    font-family: ${DEFAULT_FONT_FAMILY};
    height: 100%;
    line-height: 1;
    margin: 0 auto;
    overflow-scrolling: touch;
    overflow-x: hidden;
    padding: 0;
    width: 100%;
  }

  a {
    font-size: 1.4rem;
    font-weight: bold;
    text-decoration: none;
  
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }
  
  a,
  button {
    transition: all .3s linear;
  }
  
  select {
    appearance: none;
    background: none transparent;
    border: 0;
    border-radius: 0;
    box-sizing: content-box;
    color: inherit;
    font-size: inherit;
    margin: 0;
    padding: 0;
    vertical-align: middle;
  
    &::-ms-expand {
      display: none;
    }
  }
  
  img {
    vertical-align: bottom;
  }
  
  input,
  label,
  select,
  textarea {
    vertical-align: middle;
  }

  button {
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
  }

  table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
  }
  
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  *::before,
  *::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
`

export default globalStyle
